import { useEffect, useState } from 'react';
import React from 'redux';

import { useGameReducer } from '../../Store';
import './StartUserInfo.scss';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../Utils';

export const StartUserInfo = () => {
  const {t} = useTranslation();
  const gameReducer = useGameReducer();
  const [name, setName] = useState('');
  const [role, setRole] = useState<number>();
  const [displayName, setDisplayName] = useState('');
  const [parentDisplayName, setParentDisplayName] = useState('');

  useEffect(() => {
    if (gameReducer.userDetail) {
      setName(gameReducer.userDetail.name);
      setRole(gameReducer.userDetail.role);
      setDisplayName(gameReducer.userDetail.displayName);
      setParentDisplayName(gameReducer.userDetail.parentDisplayName);
    }
  }, [gameReducer.userDetail]);

  return (
    <div className='start-user-info'>
      <div className='user_info_widget'>
        <div className='user_name_icon member'></div>
        <div className='user_member_id'>{name}</div>
        <div className='user_member_name'>({displayName})</div>
      </div>
      <div className='user_info_widget'>
        <div className='user_name_icon parent'></div>
        <div className='user_parent_name'>{parentDisplayName}</div>
        { role === UserType.PLAYER && (
          <div className='user_parent_room'>[{t('label.vipRoom')}]</div>
        )}
      </div>
    </div>
  );
};
