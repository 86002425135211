import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../Components/Header';
import { FooterMenu } from '../../../Components/FooterMenu';
import { ResetPassword } from '../../../Components/ResetPassword';

export const ManagerResetPassword =  () => {
  const { t } = useTranslation();

  return (
    <div className='agent_layout'>
      <Header name={t('userAction.newPassword')} />
      <div className='agent_body'>
        <ResetPassword></ResetPassword>
      </div>
      <FooterMenu />
    </div>
  );
};
