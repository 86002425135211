import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../Store';
import './ResetPassword.scss';
import { UserActions } from '../../Store/Actions';

interface Props {
  isAgent?: boolean;
}

export const ResetPassword = (props: Props) => {
  const user = useUser(useParams().userId);
  const { t } = useTranslation();
  const [newCredentials, setNewCredentials] = useState<{name: string, password: string}>(null);

  const replacePassword = () => {
    UserActions.resetPassword(user.user.name).then((r: {name: string, password: string}) => {
      setNewCredentials(r);
    });
  }

  return (
    <>
      { user && (
        <div className='agent_body reset-pass'>
          <div className='row_details'>
            {!newCredentials && (
              <>
                <div className='header_box'>
                  <div className='row_details_text'>{user.user.name}</div>
                </div>
                <div className='init'>
                  <div className="description">{t('userAction.newPasswordDescription')}</div>
                  <button onClick={() => replacePassword()}>{t('password.generateButton')}</button>
                </div>
              </>
            )}
            {newCredentials && (
              <div className="login-details">
                <div className="title">{t('label.newLoginDetails')}</div>
                <div className="data">
                  <div className="label">{t('user.name')}</div>
                  <div className="value">{newCredentials.name}</div>
                </div>
                <div className="data">
                  <div className="label">{t('login.password')}</div>
                  <div className="value">{newCredentials.password}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
