import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../../Store';
import { roundBalance } from '../../../Utils';
import { DATE_FORMAT } from '../../../environment.config';
import './UserDetails.scss';
import { defaultLabels } from '../../../Utils/label.utils';
import { UserActions } from '../../../Store/Actions';

interface Props {
  isAgent?: boolean;
}

export const UserDetail = (props: Props) => {
  const navigate = useNavigate();
  const user = useUser(useParams().userId);
  const { t } = useTranslation();
  const [labels, setLabels] = useState([...defaultLabels]);

  const [editDisplayName, setEditDisplayName] = useState<string>(null);
  const [editDetails, setEditDetails] = useState<string>(null);

  const redirect = (url) => {
    navigate(url);
  };

  const toggleLabel = (label: string) => {
    if (user.user.labels.includes(label)) {
      user.user.labels.splice(user.user.labels.indexOf(label), 1);
    } else {
      user.user.labels.push(label);
      user.user.labels.sort((a, b) => a.localeCompare(b));
    }
    UserActions.setUserDetails(user.user.name, user.user).then(() => {
      setLabels([...defaultLabels]);
    });
  };

  const saveDisplayName = (displayName: string) => {
    UserActions.setUserDetails(user.user.name, {...user.user, displayName}).then(() => {
      setEditDisplayName(null);
    });
  };

  const saveDetails = (details: string) => {
    UserActions.setUserDetails(user.user.name, {...user.user, details}).then(() => {
      setEditDetails(null);
    });
  };

  return (
    <>
      { user && (
        <div className='agent_body'>
          <div className='row_details'>
            <div className='header_box'>
              <div className='row_details_text'>{user.user.name}</div>
            </div>

            <div className='row_details_inner'>
              <div className={'row_details_text align_top ' + (editDisplayName !== null ? 'min_width' : '')}>{t('user.displayName')}</div>
              { editDisplayName !== null && (
                <div className='row_details_text align_top auto_width'>
                  <div className='inline_editor'>
                    <input
                      type="text" defaultValue={user.user.displayName}
                      onChange={e => setEditDisplayName(e.target.value)}
                    />
                    <div className="inline_editor_actions">
                      <i onClick={() => saveDisplayName(editDisplayName)} className='fa-solid fa-check'></i>
                      <i onClick={() => setEditDisplayName(null)} className='fa-solid fa-close'></i>
                    </div>
                  </div>
                </div>
              )}
              {editDisplayName === null && (
                <div className='row_details_text'>
                  {user.user.displayName}
                  <i onClick={() =>setEditDisplayName(user.user.displayName)} className='fa-solid fa-edit'></i>
                </div>
              )}
            </div>

            <div className='row_details_inner'>
              <div className='row_details_text'>{t('user.balance')}</div>
              <div className='row_details_text'>{roundBalance(parseFloat(user.balance))}</div>
            </div>

            <div className='row_details_inner'>
              <div className='row_details_text'>{t('user.created')}</div>
              <div className='row_details_text'>{moment(user.user.created).format(DATE_FORMAT)}</div>
            </div>
          </div>

          <div className='game_details_box'>
            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('./DepositCredit')}>
                <span className='btn_text wheat '>{t('userAction.deposit')}</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('WithdrawCredit')}>
                <span className='btn_text wheat '>{t('userAction.withdraw')}</span>
              </div>
            </div>

            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('Transactions')}>
                <span className='btn_text wheat '>{t('userAction.transactions')}</span>
              </div>
              <div className='details_box_btn lm_btn' onClick={() => redirect('../../Messages/' + user.user.id)}>
                <span className='btn_text wheat '>{t('userAction.messages')}</span>
              </div>
            </div>

            <div className='game_details_box_inner'>
              <div className='details_box_btn lm_btn' onClick={() => redirect('ResetPassword')}>
                <span className='btn_text wheat '>{t('userAction.newPassword')}</span>
              </div>
              { !props.isAgent && (
                <div className='details_box_btn lm_btn' onClick={() => redirect('BetHistory')}>
                  <span className='btn_text wheat '>{t('userAction.betHistory')}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row_details'>
            { !props.isAgent && (
              <div className='row_details_inner'>
                <div className='row_details_text'>{t('user.label')}</div>
                <div className='row_details_text label-toggle'>
                  { labels.map(label => (
                    <button key={label} className={'label ' + (user.user.labels.includes(label) ? 'selected' : '')} onClick={() => toggleLabel(label)}>
                      {t('labels.' + label)} { user.user.labels.includes(label) && <i className='fa-solid fa-close'></i> }
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className='row_details_inner'>
              <div className={'row_details_text align_top ' + (editDetails !== null ? 'min_width' : '')}>{t('user.details')}</div>
              { editDetails !== null && (
                <div className='row_details_text align_top auto_width'>
                  <div className='inline_editor'>
                    <textarea defaultValue={user.user.details} onChange={e => setEditDetails(e.target.value)} />
                    <div className='inline_editor_actions'>
                      <i onClick={() => saveDetails(editDetails)} className='fa-solid fa-check'></i>
                      <i onClick={() => setEditDetails(null)} className='fa-solid fa-close'></i>
                    </div>
                  </div>
                </div>
              )}
              { editDetails === null && (
                <div className='row_details_text'>
                  {user.user.details}
                  <i onClick={() =>setEditDetails(user.user.details)} className='fa-solid fa-edit'></i>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
